<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row>
        <b-col>
          <b-card
            class="mb-1"
          >
            <b-button
              variant="success"
              block
              @click="saveData"
            >
              {{ $t('Save') }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <b-card
            class="mb-1"
          >
            <b-button
              variant="primary"
              block
              size="sm"
            >
              {{ $t('Example') }}

            </b-button>
            <div class="m-2">
              <b-row
                v-for="(item, index) in stockData"
                v-show="item.name !== ''"
                :key="index"
              >
                <b-form-group
                  :label="item.name"
                >
                  <b-row>
                    <b-col
                      v-for="(itemOption, indexOption) in item.option"
                      v-show="itemOption.name !== ''"
                      :key="indexOption"
                      class="mt-1"
                    >
                      <b-button
                        v-if="itemOption.name.length>0"
                        size="sm"
                        :variant="itemOption._id !== null?'success':'dark'"
                        @click="clickSelect(index, indexOption)"
                      >
                        {{ itemOption.name }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form-group>

              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col sm="8">
          <b-card class="mb-1">
            <div>
              <div class="d-flex align-items-center justify-content-end">

                <b-button
                  v-if="stockData.length !== 2 "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="d-flex mb-2"
                  @click="addCategory()"
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
              </div>
              <b-table-simple
                variant="primary"
                hover
                small
                responsive
                class="position-relative"
              >
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-th class="text-center">
                      {{ $t('Category Option') }}
                    </b-th>
                    <b-th class="text-center">
                      {{ $t('Option') }}
                    </b-th>
                    <b-th class="text-center">
                      {{ $t('ACTION') }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(item,index) in stockDataTable"
                    :key="index"
                  >
                    <b-th
                      v-if="item.firstList === true"
                      :rowspan="item.firstList === true? item.optionLength: null"
                    >
                      <div class="d-flex align-items-center justify-content-end">

                        <b-form-input
                          v-model="item.categoryName"
                          class="d-inline-block"
                          :placeholder="$t('Category')"
                        />
                        <b-button
                          v-if="stockData.length>1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          class="d-flex"
                          @click="deleteCategory(item.indexCategory)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                          />
                        </b-button>
                      </div>
                    </b-th>
                    <b-td class="text-center">
                      <b-form-input
                        v-model="item.name"
                        class="d-inline-block"
                        :placeholder="$t('Option')"
                      />
                    </b-td>
                    <b-td class="text-center">
                      <div class="d-flex align-items-center justify-content-end">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="danger"
                          class="d-flex"
                          @click="deleteOption(item.indexCategory,item.index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                          />
                        </b-button>
                        <b-button
                          v-if="item.endList"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          class="d-flex ml-2"
                          @click="addOption(item.indexCategory)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                        </b-button>
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>

          </b-card>
          <!-- <b-card
            v-for="(item, index) in stockData"
            :key="index"
            class="mb-1"
          >
            <b-card-text>
              <b-button :variant="item._id !== null ?'success' :'warning'">
                {{ $t('Category Option') }}
              </b-button>

            </b-card-text>
            <div class="m-2">
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <b-form-input
                    v-model="item.name"
                    class="d-inline-block mr-1"
                    :placeholder="$t('Search')"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <b-button
                      v-if="stockData.length>1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="d-flex"
                      @click="deleteCategory(index)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                    </b-button>
                    <b-button
                      v-if="stockData.length-1 === index && stockData.length !== 2 "
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="d-flex ml-2"
                      @click="addCategory()"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row
                v-for="(itemOption, indexOption) in item.option"
                :key="indexOption"
                class="mt-2"
              >
                <b-col cols="2">
                  <b-button
                    :variant="itemOption._id !== null?'primary':'dark'"
                    block
                    size="sm"
                  >
                    {{ $t('Option') }} :
                  </b-button>
                </b-col>
                <b-col cols="7">
                  <b-form-input
                    v-model="itemOption.name"
                    class="d-inline-block mr-1"
                    :placeholder="$t('Option')"
                  />
                </b-col>
                <b-col cols="3">
                  <div class="d-flex align-items-center justify-content-end">

                    <b-button
                      v-if="item.option.length>1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="d-flex"
                      @click="deleteOption(index,indexOption)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                    </b-button>
                    <b-button
                      v-if="item.option.length-1 === indexOption"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="d-flex ml-2"
                      @click="addOption(index)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">

          <b-card
            class="mb-1"
          >
            <b-button
              variant="primary"
              block
              size="sm"
              class="mb-1"
            >
              {{ $t('All option') }} : {{ filterOption(optionAllAndAmount).length }}
            </b-button>
            <div>
              <b-table-simple
                hover
                small
                responsive
                class="position-relative"
              >
                <b-thead head-variant="primary">
                  <b-tr>
                    <b-th
                      v-for="(item, index) in stockData"
                      :key="index"
                      class="text-center"
                    >
                      {{ item.name }}
                    </b-th>
                    <b-th class="text-center">
                      {{ $t('Stock') }}
                    </b-th>
                    <b-th class="text-center">
                      {{ $t('Price') }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(item,index) in optionAllAndAmount"
                    :key="index"
                  >
                    <b-th
                      v-for="(itemOption, indexOption) in item.children"
                      :key="indexOption"
                      class="text-center"
                    >
                      {{ itemOption.name }}
                    </b-th>
                    <b-td>
                      <b-form-input
                        id="register-Stock"
                        v-model="item.stock"
                        name="register-Stock"
                        :placeholder="$t('Stock')"
                      />
                    </b-td>
                    <b-td>
                      <b-form-input
                        id="register-Price"
                        v-model="item.price"
                        name="register-Price"
                        :placeholder="$t('Price')"
                      />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <!-- <b-button
              variant="primary"
              block
              size="sm"
              class="mb-1"
            >
              {{ $t('All option') }} : {{ filterOption(optionAllAndAmount).length }}
            </b-button>
            <div class="">
              <b-row>
                <b-col
                  v-for="(item, index) in stockData"
                  :key="index"
                >
                  <p class="text-center">
                    {{ item.name }}
                  </p>
                </b-col>
              </b-row>
              <div
                v-for="(item, index) in filterOption(optionAllAndAmount)"
                :key="index"
              >
                <b-row>
                  <b-col
                    v-for="(itemOption, indexOption) in item.children"
                    :key="indexOption"
                    class="mt-1"
                  >
                    <b-button
                      block
                      size="sm"
                      :variant="itemOption._id !== null?'success':'dark'"
                    >
                      {{ itemOption.name }}
                    </b-button>
                  </b-col>
                  <b-col sm="12">
                    <b-row>
                      <b-col sm="6">
                        <b-form-group
                          :label="$t('Stock')"
                          label-for="register-Stock"
                          class="mt-1"
                        >
                          <b-form-input
                            id="register-Stock"
                            v-model="item.stock"

                            name="register-Stock"
                            :placeholder="$t('Stock')"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group
                          :label="$t('Price')"
                          label-for="register-Price"
                          class="mt-1"
                        >
                          <b-form-input
                            id="register-Price"
                            v-model="item.price"

                            name="register-Price"
                            :placeholder="$t('Price')"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                  </b-col>
                </b-row>
                <hr>

              </div>

            </div> -->
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BCardText, BAvatar, BRow, BCol, BFormInput, BImg, BButton, BOverlay, BTableSimple,
  BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import perPageOptions from '@/perPageOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '@/store/services/hero/product'
import Ripple from 'vue-ripple-directive'

const STORE_MODULE_NAME = 'product'

export default {
  components: {
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BTableSimple,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    BThead,
    BTbody,
    BTr,
    BTh,
    // eslint-disable-next-line vue/no-unused-components
    BTd,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      selected: [],
      stockData: [
        {
          _id: null,
          name: '',
          option: [{
            _id: null,
            name: '',
          }],
        }],
      stockDataTable: [],
      optionAllAndAmount: [],
      optionDetail: [],
      perPageOptions,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'name', label: this.$t('Name'), sortable: false },
        { key: 'option', label: this.$t('Option'), sortable: false },
      ]
    },
  },
  watch: {
    stockData: {
      handler() {
        this.seeAllOption()
        this.addAmount(this.optionDetail)
      },
      deep: true,
    },
    stockDataTable: {
      handler() {
        this.convertTableToDataStock()
      },
      deep: true,
    },
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.show = true
    store
      .dispatch(`${STORE_MODULE_NAME}/getStock`, {
        id: this.$route.params.id,
      })
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        // eslint-disable-next-line no-unused-vars
        const { data, optionDetail } = result.data
        if (data.length > 0) {
          this.stockData = data
        }
        this.stockDataTableFormat()

        this.optionDetail = optionDetail

        this.seeAllOption()
        if (optionDetail.length > 0) {
          this.addAmount(optionDetail)
        }
        this.show = false

        
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods: {
    convertTableToDataStock() {
      const result = []
      for (let index = 0; index < this.stockDataTable.length; index += 1) {
        if (this.stockDataTable[index].categoryId !== null) {
          const indexWhere = result.findIndex(e => e._id.toString() === this.stockDataTable[index].categoryId.toString())
          if (indexWhere > -1) {
            result[indexWhere].option.push({
              _id: this.stockDataTable[index]._id,
              name: this.stockDataTable[index].name,
              productCategoryOptionId: this.stockDataTable[index].categoryId,
            })
          } else {
            result.push({
              _id: this.stockDataTable[index].categoryId,
              name: this.stockDataTable[index].categoryName,
              option: [{
                _id: this.stockDataTable[index]._id,
                name: this.stockDataTable[index].name,
                productCategoryOptionId: this.stockDataTable[index].categoryId,
              }],
            })
          }
        } else {
          console.log(this.stockDataTable[index].indexCategory)
          if (result[this.stockDataTable[index].indexCategory] !== null) {
            if (result[this.stockDataTable[index].indexCategory]) {
              if (result[this.stockDataTable[index].indexCategory].option.length > 0) {
                result[this.stockDataTable[index].indexCategory].option.push({
                  _id: null,
                  name: this.stockDataTable[index].name,
                  productCategoryOptionId: this.stockDataTable[index].categoryId,
                })
              }
            } else {
              result[this.stockDataTable[index].indexCategory] = {
                _id: null,
                name: this.stockDataTable[index].categoryName,
                option: [{
                  _id: null,
                  name: this.stockDataTable[index].name,
                  productCategoryOptionId: this.stockDataTable[index].categoryId,
                }],
              }
            }
          } else {
            result.push({
              _id: null,
              name: this.stockDataTable[index].categoryName,
              option: [{
                _id: null,
                name: this.stockDataTable[index].name,
                productCategoryOptionId: this.stockDataTable[index].categoryId,
              }],
            })
          }
        }
      }
      this.stockData = result
    },
    stockDataTableFormat() {
      this.stockDataTable = this.stockData
      let result = []
      for (let index = 0; index < this.stockData.length; index += 1) {
        let dataOption = this.stockData[index].option
        dataOption = dataOption.map((e, indexOption) => ({
          index: indexOption,
          firstList: indexOption === 0,
          endList: indexOption === this.stockData[index].option.length - 1,
          _id: e._id,
          name: e.name,
          indexCategory: index,
          categoryId: this.stockData[index]._id,
          categoryName: this.stockData[index].name,
          optionLength: dataOption.length,
        }))
        result = result.concat(dataOption)
      }
      this.stockDataTable = result
    },
    filterOption(data) {
      return data
    },
    checkSelect(index, indexOption) {
      const indexSelected = this.selected.findIndex(e => e[0] === index && e[1] === indexOption)
      if (indexSelected > -1) {
        return true
      }
      return false
    },
    clickSelect(index, indexOption) {
      const indexSelected = this.selected.findIndex(e => e[0] === index && e[1] === indexOption)
      if (indexSelected > -1) {
        this.selected.splice(indexSelected, 1)
      } else {
        this.selected.push([index, indexOption])
      }
    },
    saveData() {
      const check = this.stockData.every(e => e.name !== '' && e.option.every(i => i.name !== ''))
      if (check && this.stockData.length > 0) {
        this.show = true
        store
          .dispatch(`${STORE_MODULE_NAME}/saveStock`, {
            id: this.$route.params.id,
            stockData: this.stockData,
            optionAllAndAmount: this.optionAllAndAmount,
          })
        // eslint-disable-next-line no-unused-vars
          .then(result => {
          // eslint-disable-next-line no-unused-vars
            const { data, optionDetail } = result.data
            this.stockData = data
            this.stockDataTableFormat()
            this.optionDetail = optionDetail
            this.seeAllOption()
            if (optionDetail.length > 0) {
              this.addAmount(optionDetail)
            }
            this.show = false

            
            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
          }).catch(error => {
            this.show = false

            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            // console.log('fetchUsers Error : ', error)
            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t('Invalid data'),
          },
        })
      }
    },
    seeAllOption() {
      const options = []
      // eslint-disable-next-line no-unused-vars
      this.stockData.forEach((item, index) => {
        const { option } = item
        options.push(option)
      })
      const combinations = []

      const generateCombinations = (arr, index, current) => {
        if (index === arr.length) {
          combinations.push(current)
        } else {
          for (let i = 0; i < arr[index].length; i += 1) {
            const newCurrent = current.slice()
            newCurrent.push({
              _id: arr[index][i]._id, name: arr[index][i].name, index, indexOption: i,
            })
            generateCombinations(arr, index + 1, newCurrent)
          }
        }
      }

      generateCombinations(options, 0, [])
      const obj = []
      // eslint-disable-next-line array-callback-return
      combinations.map((item, index) => {
        if (this.optionAllAndAmount[index]) {
          obj[index] = { children: item, stock: 0, price: 0 }
        } else {
          obj.push({ children: item, stock: 0, price: 0 })
        }
      })
      this.optionAllAndAmount = obj
    },
    addAmount(data) {
      for (let i = 0; i < this.optionAllAndAmount.length; i += 1) {
        for (let k = 0; k < data.length; k += 1) {
          const arraysMatch = this.optionAllAndAmount[i].children.map(e => e._id).every((val, index) => val === data[k].productOptionId[index])
          if (arraysMatch) {
            this.optionAllAndAmount[i].price = data[k].price
            this.optionAllAndAmount[i].stock = data[k].stock
          }
        }
      }
    },
    deleteCategory(index) {
      this.stockData.splice(index, 1)
      this.stockDataTableFormat()
    },
    addCategory() {
      if (this.stockData.length < 2) {
        this.stockData.push({
          _id: null,
          name: '',
          option: [{
            _id: null,
            name: '',
          }],
        })
        this.stockDataTableFormat()
      }
    },
    deleteOption(index, indexOption) {
      this.stockData[index].option.splice(indexOption, 1)
      this.stockDataTableFormat()
    },
    addOption(index) {
      if (this.stockData[index] !== null) {
        this.stockData[index].option.push({
          _id: null,
          name: '',
        })
      }

      this.stockDataTableFormat()
    },
    showFromCurrentLanguage(data) {
      console.log(data)
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
